import { cn } from "~/lib/utils"
import { buttonVariants } from "~/components/ui/button"
import { Icons } from "~/components/icons"
import { Meta, Title } from "@solidjs/meta"
import {
  action,
  json,
  redirect,
  useNavigate,
  useSubmission,
} from "@solidjs/router"
import { getNewPocketBase } from "../../lib/pb"
import { ClientResponseError } from "pocketbase"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import { to } from "await-to-js"

interface errors {
  [attr: string]: string
}
const loginAction = action(
  async (formData: FormData): Promise<{ errors: errors }> => {
    "use server"
    const errors: errors = {}
    const email = formData.get("email") as string | null
    if (!email) {
      errors.email = "请输入邮箱"
    }

    const password = formData.get("password") as string | null
    if (!password) {
      errors.password = "请输入密码"
    }

    if (Object.keys(errors).length > 0) {
      return json({ errors })
    }

    // 去注册，使用 pocketbase 的 API
    let pb = getNewPocketBase()

    let [error, record] = await to<any, ClientResponseError>(
      pb.collection("users").authWithPassword(email!, password!),
    )
    if (error) {
      console.error(error)
      return json({
        errors: {
          other:
            error.response.data?.email?.message || error.message || "登录失败",
        },
      })
    } else {
      return redirect("/dashboard", {
        headers: {
          "Set-Cookie": pb.authStore.exportToCookie({ httpOnly: true }),
        },
      })
    }
  },
)

export default function LoginPage() {
  const submission = useSubmission(loginAction)
  const navigate = useNavigate()
  return (
    <div class="container flex h-screen w-screen flex-col items-center justify-center">
      <Title>登录</Title>
      <Meta name="description" content="登录" />
      <a
        onClick={() => navigate(-1)}
        class={cn(
          buttonVariants({ variant: "ghost" }),
          "absolute left-4 top-4 md:left-8 md:top-8",
        )}
      >
        <Icons.chevronLeft class="mr-2 h-4 w-4" />
        Back
      </a>
      <div class="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
        <div class="flex flex-col space-y-2 text-center">
          <Icons.logo class="mx-auto h-6 w-6" />
          <h1 class="text-2xl font-semibold tracking-tight">登录</h1>
          <p class="text-sm text-muted-foreground">输入邮箱密码登录</p>
        </div>
        <div class="grid gap-6">
          <form action={loginAction} method="post">
            <div class="grid gap-2">
              <div class="grid gap-1">
                <Label for="email">邮箱：</Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                  type="email"
                  disabled={submission.pending}
                />
                {submission.result?.errors?.email && (
                  <p class="px-1 text-xs text-red-600">
                    {submission.result?.errors?.email}
                  </p>
                )}
              </div>
              <div class="grid gap-1">
                <Label for="password">密码：</Label>
                <Input
                  id="password"
                  name="password"
                  placeholder=""
                  type="password"
                  disabled={submission.pending}
                />
                {submission.result?.errors?.password && (
                  <p class="px-1 text-xs text-red-600">
                    {submission.result?.errors?.password}
                  </p>
                )}
                {submission.result?.errors?.other && (
                  <p class="px-1 text-xs text-red-600">
                    {submission.result?.errors?.other}
                  </p>
                )}
              </div>
              <button
                class={cn(buttonVariants())}
                disabled={submission.pending}
              >
                {submission.pending && (
                  <Icons.spinner class="mr-2 h-4 w-4 animate-spin" />
                )}
                登录
              </button>
            </div>
          </form>
          {/* <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <span class="w-full border-t" />
              </div>
              <div class="relative flex justify-center text-xs uppercase">
                <span class="bg-background px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div> */}
          {/* <button
              type="button"
              class={cn(buttonVariants({ variant: "outline" }))}
              onClick={() => {
                setIsGitHubLoading(true)
                signIn("github")
              }}
              disabled={isLoading() || isGitHubLoading()}
            >
              {isGitHubLoading() ? (
                <Icons.spinner class="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Icons.gitHub class="mr-2 h-4 w-4" />
              )}{" "}
              Github
            </button> */}
        </div>
        <p class="px-8 text-center text-sm text-muted-foreground">
          <a
            href="/auth/signup"
            class="hover:text-brand underline underline-offset-4"
          >
            没有账号？注册
          </a>
        </p>
      </div>
    </div>
  )
}
